export enum ACTION_LOADER {
  CHANGE_LOADING_STATUS = "loader/changeLoadingStatus",
}

export enum ACTION_ERROR {
  CHANGE_LOADING_STATUS = "error/changeErrorStatus",
}
export enum HOME {
  FETCH_INITIAL_STORE = "homeInfo/fetchInitialStore",
  GET_INITIAL_STORE = "homeInfo/getInitialStore",
  GET_PRIME_CATEGORY = "homeInfo/getPrimeCategory",
  FETCH_PRIME_CATEGORY = "homeInfo/fetchPrimaCategory",
  GET_LOADER_STATE = "homeInfo/getLoaderState",
  SET_LOADER_STATE = "homeInfo/setLoaderState",
  FETCH_PRIME_CATEGORY_STORE = "homeInfo/fetchPrimeCategoryStore",
  GET_PRIME_CATEGORY_STORE = "homeInfo/getPrimeStore",
  GET_FILTER_CATEGORY = "homeInfo/getFilterCategory",
  FETCH_FILTER_CATEGORY = "homeInfo/fetchFilterCategory",
  GET_FILTER_PRODUCT = "homeInfo/getFilterProduct",
  GET_FILTER_PRODUCT_AUTOCOMPLETE = "homeInfo/getFilterProductAutocomplete",
  FETCH_FILTER_PRODUCT = "homeInfo/fetchFilterProduct",
  FETCH_FILTER_PRODUCT_AUTOCOMPLETE = "homeInfo/fetchFilterProductAutocomplete",
  SEND_CONTACT = "homeInfo/sendContact",
  SEND_NEWSLETTER = "homeInfo/sendNewsLetter",
  FETCH_OFFER = "homeInfo/fetchOffer",
  GET_OFFER = "homeInfo/getOffers",
}

export enum STORE_PRODUCT {
  FETCH_STORE_PRODUCT = "storeProductInfo/fetchStoreProductV2",
  GET_STORE_PRODUCT = "storeProductInfo/getStoreProduct",
  GET_STORE_CATEGORY = "storeProductInfo/getStoreCategory",
  FETCH_STORE_PRODUCT_DETAILS = "storeProductInfo/fetchStoreProductDetails",
  GET_STORE_PRODUCT_DETAILS = "storeProductInfo/getProductDetails",
  FETCH_DELIVERY_FEE = "storeProductInfo/fetchStoreDeliveryFee",
  GET_DELIVERY_FEE = "storeProductInfo/getStoreDeliveryFee",
  GET_CATEGORY_BY_PRIME = "storeProductInfo/getCategoryByPrime",
  FETCH_CATEGORY_BY_PRIME = "storeProductInfo/fetchCategoryByPrime",
  FETCH_FILTER_PRODUCT = "storeProductInfo/filterStoreProduct",
}
export enum AUTH {
  "AUTH_LOGIN" = "authInfo/authLogin",
  "AUTH_REFRESH" = "authInfo/getRefreshToken",
  "AUTH_REGISTER" = "authInfo/authRegister",
  "AUTH_ME" = "authInfo/getAuthUser",
  "AUTH_ME_INFO" = "authInfo/getUser",
  "AUTH_VERIFY_OTP" = "authInfo/verifyOtp",
  "AUTH_GENERATE_OTP" = "authInfo/generateOtp",
  "AUTH_LOGOUT" = "authInfo/logoutAuth",
  "GET_USER_ADDRESS" = "authInfo/getUserAddress",
  FETCH_USER_ADDRESS = "authInfo/fetchUserAddress",
  ADD_USER_ADDRESS = "authInfo/addUserAddress",
  DELETE_USER_ADDRESS = "authInfo/deleteUserAddress",
  MARK_USER_ADDRESS = "authInfo/markUserAddressSelected",
  UPDATE_AUTH = "authInfo/authUpdate",
  AUTH_PASSWORD = "authInfo/authPasswordUpdate",
}

export enum ORDER {
  "MAKE_ORDER" = "orderInfo/makeOrder",
  "FETCH_ALL" = "orderInfo/fetchAllOrder",
  "GET_PLACED_ORDER" = "orderInfo/getPlacedOrder",
  "FETCH_ORDER_DETAILS" = "orderInfo/fetchOrderDetails",
  "GET_ORDER_DETAILS" = "orderInfo/getOrderDetails",
  "POST_DRIVER_REVIEW" = "orderInfo/postDriverReview",
  "POST_STORE_REVIEW" = "orderInfo/postStoreReview",
}
export enum WALLET {
  "FETCH_WALLET" = "walletInfo/fetchTransaction",
  "GET_WALLET" = "walletInfo/getWallet",
}
