import { useAxios } from "@/services/axios";
import { HTTP_WALLET } from "@/services/enums/httpEnums";
export const walletTotalOrder = (queryParam: any): any =>
  useAxios.get(HTTP_WALLET.WALLET_TOTAL_ORDER, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const WalletTodalPurchased = (queryParam: any): any =>
  useAxios.get(HTTP_WALLET.WALLET_TOTAL_PURCHASED, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const WalletTransactionHistory = (queryParam: any): any =>
  useAxios.get(HTTP_WALLET.WALLET_TOTAL_TRANSACTION, {
    params: {
      ...queryParam.queryParam,
    },
  });
