export const storeProductMutations: any = {
  SET_STORE_PRODUCT(state: any, payload: any) {
    state.store_product = payload;
  },
  SET_STORE_CATEGORY(state: any, payload: any) {
    state.store_category = payload;
  },
  SET_STORE_PRODUCT_DETAILS(state: any, payload: any) {
    state.store_product_details = payload;
  },
  SET_STORE_DELIVERY_FEE(state: any, payload: any) {
    state.delivery_fee = payload;
  },
  SET_CATEGORY_BY_PRIME(state: any, payload: any) {
    state.category_by_prime = payload;
  },
};
