export const homeMutations: any = {
  SET_INITIAL_STORE(state: any, payload: any) {
    state.initial_store = payload;
  },
  SET_PRIME_CATEGORY(state: any, payload: any) {
    state.prime_category = payload;
  },
  SET_LOADER_STATE(state: any, payload: any) {
    state.loader_state = payload;
  },
  SET_PRIME_CATEGORY_STORE(state: any, payload: any) {
    state.prime_category_store = payload;
  },
  SET_FILTER_CATEGORY(state: any, payload: any) {
    state.filter_category = payload;
  },
  SET_FILTER_PRODUCT(state: any, payload: any) {
    state.filter_product = payload;
  },
  SET_FILTER_PRODUCT_AUTOCCOMPLETE(state: any, payload: any) {
    state.filter_product_autocomplete = payload;
  },
  SET_OFFER(state: any, payload: any) {
    state.offers = payload;
  },
};
