import { Module } from "vuex";

import { walletActions as actions } from "./action";
import { walletMutations as mutations } from "./mutation";
import { walletGetters as getters } from "./getter";

const state: any = { isAllRequiredFieldLoaded: false };

export const walletInfo: any = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state,
};
