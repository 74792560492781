import { useAxios } from "@/services/axios";
import { HTTP_HOME } from "@/services/enums/httpEnums";
export const fetchTopStore = (queryParam: any): any =>
  useAxios.get(HTTP_HOME.GET_TOP_STORE, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const fetchRecentStore = (queryParam: any): any =>
  useAxios.get(HTTP_HOME.GET_RECENT_STORE, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const fetchNearByStore = (queryParam: any): any =>
  useAxios.get(HTTP_HOME.GET_NEAR_BY_STORE, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const fetchMoreStore = (queryParam: any): any =>
  useAxios.get(HTTP_HOME.GET_MORE_STORE, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const fetchPrimeCategoryStore = (queryParam: any): any =>
  useAxios.get(HTTP_HOME.GET_PRIME_CATEGORY_STORE, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const fetchFilterCategory = (queryParam: any): any =>
  useAxios.get(HTTP_HOME.FETCH_FILTER_CATEGORY, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const fetchFilterProduct = (queryParam: any): any =>
  useAxios.get(HTTP_HOME.FETCH_FILTER_PRODUCT, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const fetchFilterProductAutocomplete = (queryParam: any): any =>
  useAxios.get(HTTP_HOME.FETCH_FILTER_PRODUCT_AUTOCOMPLETE, {
    params: {
      ...queryParam.queryParam,
    },
    headers:{
      loader:false
    }
  },
  
  );
export const fetchOffer = (queryParam: any): any =>
  useAxios.get(HTTP_HOME.FETCH_OFFER, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const fetchPrimeCategory = (): any =>
  useAxios.get(HTTP_HOME.GET_PRIME_CATEGORY);

export const sendContact = (queryParam: any): any =>
  useAxios.post(HTTP_HOME.SEND_CONTACT, {
    ...queryParam.queryParam,
  });
export const sendNewsLetter = (queryParam: any): any =>
  useAxios.post(HTTP_HOME.SEND_NEWSLETTER, {
    ...queryParam.queryParam,
  });
