export const authMutations: any = {
  SET_USER(state: any, payload: any) {
    console.log(payload);
    state.user = payload.user;
  },
  SET_USER_ME(state: any, payload: any) {
    console.log("========", payload);
    state.user = payload.user;
  },
  LOGOUT_USER(state: any, payload: any) {
    state.user = null;
  },
  SET_USER_ADDRESS(state: any, payload: any) {
    state.user_address = payload;
  },
};
