import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";
const app = createApp(App);
// import BaseComponents from "./components/reusable/*.vue";
// BaseComponents.register(app);
const options: PluginOptions = {
  // You can set your default options here
};


// import { VueRecaptchaPlugin } from 'vue-recaptcha'

// app.use(VueRecaptchaPlugin, {
//   v2SiteKey: '6LfiLykmAAAAACGUPfvSlc1p57BMAuSAAs__bhF_'
// })
app.use(Toast, options);
app.config.globalProperties.$pounds = "£";
app.use(store).use(router).mount("#app");
