import { store } from "@/store";

/**
 * axiosObj take generic objects and sets the property types based on those generic objects
 */
interface axiosObj<X, Y> {
  /**
   * Optional payload
   */
  payload?: X;
  /**
   * Optional payload
   */
  queryParams?: Y;
}

/**
 * @interface vuexAction - vuex actions reference.
 * see {@link https://www.typescriptlang.org/docs/handbook/declaration-files/do-s-and-don-ts.html Do's and dont's}
 * and {@link https://www.typescriptlang.org/docs/handbook/jsdoc-supported-types.html JSDoc Reference}.
 * Should we perhaps consider using {@link https://www.typescriptlang.org/docs/handbook/declaration-files/introduction.html declaration files}?
 */
interface vuexAction {
  actionName: string;
}

/**
 *  Whenever this function is being called, the caller should decide the object type which has to be sent to server as request body or query params
 *
 * @param {object} vuexObject should have vuex action name and payload if required.
 * @param {object} axiosObject should have query params if required.
 * @param {object} abortController A controller object that allows you to abort one or more DOM requests as and when desired.
 */
export async function executeHTTPAction<X, Y>(
  vuexObject: vuexAction,
  axiosObject?: axiosObj<X, Y>,
  abortController?: AbortController
): Promise<void> {
  return await store.dispatch(vuexObject.actionName, {
    data: axiosObject?.payload,
    queryParam: axiosObject?.queryParams,
    abortController,
  });
}
