import { Module } from "vuex";

import { orderActions as actions } from "./action";
import { orderMutations as mutations } from "./mutation";
import { orderGetters as getters } from "./getter";

const state: any = { isAllRequiredFieldLoaded: false };

export const orderInfo: any = {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
};
