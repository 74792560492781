import { store } from "@/store";
import { HOME } from "@/services/enums/vuexEnums";

export default class Loader {
  static showLoader() {
    store.dispatch(HOME.SET_LOADER_STATE, {
      active: true,
    });
  }
  static hideLoader() {
    // setTimeout(() => {
      store.dispatch(HOME.SET_LOADER_STATE, {
        active: false,
      });
    // }, 1000);
  }
}
