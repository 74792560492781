import { useAxios } from "@/services/axios";
import { HTTP_AUTH } from "@/services/enums/httpEnums";
export const authLogin = (queryParam: any): any =>
  useAxios.post(HTTP_AUTH.AUTH_LOGIN, {
    ...queryParam.queryParam,
  });
export const authRegister = (queryParam: any): any =>
  useAxios.post(HTTP_AUTH.AUTH_REGISTER, {
    ...queryParam.queryParam,
  });
export const authUpdate = (queryParam: any): any => {
  // const config = {
  //   headers: { "content-type": "multipart/form-data" },
  // };
  return useAxios.post(HTTP_AUTH.AUTH_UPDATE, {
    ...queryParam.queryParam,
  });
};
export const authPasswordUpdate = (queryParam: any): any =>
  useAxios.post(HTTP_AUTH.AUTH_PASSWORD, {
    ...queryParam.queryParam,
  });
export const verifyOtp = (queryParam: any): any =>
  useAxios.post(HTTP_AUTH.AUTH_VERIFY_OTP, {
    ...queryParam.queryParam,
  });
export const generateOtp = (queryParam: any): any =>
  useAxios.post(HTTP_AUTH.AUTH_GENERATE_OTP, {
    ...queryParam.queryParam,
  });
export const authMe = (): any => useAxios.get(HTTP_AUTH.AUTH_ME);
export const authRefresh = (): any => useAxios.get(HTTP_AUTH.AUTH_REFRESH);
export const fetchUserAddress = (): any =>
  useAxios.get(HTTP_AUTH.FETCH_USER_ADDRESS);

export const addUserAddress = (queryParam: any): any =>
  useAxios.get(HTTP_AUTH.ADD_USER_ADDRESS, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const deleteUserAddress = (queryParam: any): any =>
  useAxios.get(HTTP_AUTH.DELETE_USER_ADDRESS, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const markUserAddressSelected = (queryParam: any): any =>
  useAxios.get(HTTP_AUTH.MARK_USER_ADDRESS, {
    params: {
      ...queryParam.queryParam,
    },
  });
