export const storeProductGetters: any = {
  getStoreProduct(state: any) {
    return state.store_product;
  },
  getStoreCategory(state: any) {
    return state.store_category;
  },
  getProductDetails(state: any) {
    return state.store_product_details;
  },
  getStoreDeliveryFee(state: any) {
    return state.delivery_fee;
  },
  getCategoryByPrime(state: any) {
    return state.category_by_prime;
  },
};
