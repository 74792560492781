import { useAxios } from "@/services/axios";
import { HTTP_ORDER } from "@/services/enums/httpEnums";
export const makeOrder = (queryParam: any): any => {
  return useAxios.post(HTTP_ORDER.MAKE_ORDER, {
    ...queryParam.queryParam,
  });
};
export const fetchOrderDetails = (queryParam: any): any => {
  console.log("here", queryParam);
  return useAxios.get(HTTP_ORDER.FETCH_ORDER_DETAILS, {
    params: {
      ...queryParam.queryParam,
    },
  });
};
export const postStoreReview = (queryParam: any): any => {
  return useAxios.get(HTTP_ORDER.POST_STORE_REVIEW, {
    params: {
      ...queryParam.queryParam,
    },
  });
};
export const postDriverReview = (queryParam: any): any => {
  return useAxios.get(HTTP_ORDER.POST_DRIVER_REVIEW, {
    params: {
      ...queryParam.queryParam,
    },
  });
};

export const fetchAllOrder = (queryParam: any): any => {
  // console.log(queryParam.queryParam);
  if (queryParam.queryParam.tag === "all") {
    return useAxios.get(
      HTTP_ORDER.FETCH_ALL_ORDER.replace("{tag}", "activity-all-order-v2"),{
        params:{
          ...queryParam.queryParam,
        }
      }
    );
  }
  if (queryParam.queryParam.tag === "cancelled") {
    return useAxios.get(
      HTTP_ORDER.FETCH_ALL_ORDER.replace("{tag}", "activity-order-canceled"),
      {
        ...queryParam.queryParam,
      }
    );
  }
  if (queryParam.queryParam.tag === "delivered") {
    return useAxios.get(
      HTTP_ORDER.FETCH_ALL_ORDER.replace("{tag}", "activity-order-deliver"),
      {
        ...queryParam.queryParam,
      }
    );
  }
  if (queryParam.queryParam.tag === "ongoing") {
    return useAxios.get(
      HTTP_ORDER.FETCH_ALL_ORDER.replace("{tag}", "activity-order-ongoing"),
      {
        ...queryParam.queryParam,
      }
    );
  }
};
