import { executeHTTPAction } from "@/services/helper";
import { AUTH } from "@/services/enums/vuexEnums";
export const getCookies = (name: string): string => {
  return "";
};

export const saveLatLong = (latLong: any) => {
  localStorage.setItem("user_lat_long", JSON.stringify(latLong));
};
export const saveAddress = (value: any) => {
  localStorage.setItem("browse_address", value);
};
export const getBrowseAddress = () => {
  return localStorage.getItem("browse_address");
};
export const getLatLong = () => {
  const retriveLatLong: any = localStorage.getItem("user_lat_long");
  return JSON.parse(retriveLatLong);
};

export const saveJwtToken = (jwt: any) => {
  localStorage.setItem("jwt_key", jwt);
};

export const getJwtToken = () => {
  return localStorage.getItem("jwt_key");
};

export const removeJwtToken = () => {
  localStorage.removeItem("jwt_key");
};

export const saveOtpPhone = (payload: any) => {
  localStorage.setItem("otp_phone", payload);
};
export const removeOtpPhone = () => {
  localStorage.removeItem("otp_phone");
};
export const getOtpPhone = () => {
  return localStorage.getItem("otp_phone");
};

export const generateOtp = async (payload: any) => {
  await executeHTTPAction(
    { actionName: AUTH.AUTH_GENERATE_OTP },
    { queryParams: payload }
  ).then((response) => {
    // console.log(response);
    // saveJwtToken(response.data.user.meta.access_token);
    // router.go(-1);
  });
};

export const conversationTofixed = (value: any) => {
  // return value.toFixed(2);
  if (value) {
    return Number(value.toFixed(2));
  }
  return 0;
};

export const fetchUserAddress = async () => {
  await executeHTTPAction({ actionName: AUTH.FETCH_USER_ADDRESS });
};

export const numberFormat = (num: any) => {
  return parseFloat(num).toFixed(2);
};

export const reArrangWoord = (str: any) => {
  if(!str){
    return;
  }

  return str.replace(/[^A-Z0-9]/gi, "_");
};
export const getReplacementString = (str: any) => {
  return str.substring(0, 20) + "...";
};

export const getSpecificValueFromArray = (
  array: any,
  value: any,
  isColor = true
) => {
  var result = "";
  array.forEach((element: any) => {
    if (element.id == value) {
      result = value = isColor ? element.colorname : element.name;
      return true;
    }
  });
  return result;
};

export const floatToFixed = (num:string) =>{
  return parseFloat(num).toFixed(2);
}
