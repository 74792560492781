export enum HTTP_HOME {
  GET_TOP_STORE = "api/v1/get-top-store",
  GET_RECENT_STORE = "api/v1/get-recent-store",
  GET_NEAR_BY_STORE = "api/v1/nearby-store",
  GET_MORE_STORE = "api/v1/get-more-store",
  GET_PRIME_CATEGORY = "api/v1/get-prime-category",
  GET_PRIME_CATEGORY_STORE = "api/v1/get-shop-with-prime-category",
  FETCH_FILTER_CATEGORY = "api/v1/get-filter-category",
  FETCH_FILTER_PRODUCT = "api/v1/product-search",
  FETCH_FILTER_PRODUCT_AUTOCOMPLETE = "api/v1/product-search-autocomplete",
  SEND_CONTACT = "api/v1/contact",
  SEND_NEWSLETTER = "api/v1/subscribe-newsletter",
  FETCH_OFFER = "api/v1/get-coupon-list",
}
export enum HTTP_STORE_PRODUCT {
  GET_STORE_PRODUCT = "api/v1/get-shop-products-list",
  GET_STORE_PRODUCT_V2 = "api/v1/get-shop-products-list-v2",
  GET_STORE_PRODUCT_DETAILS = "api/v1/get-product-details-v2",
  GET_STORE_DELIVERY_FEE = "api/v1/get-individual-delivery-fee",
  FETCH_CATEGORY_BY_PRIME = "api/v1/get-category-list-by-prime-category",
  FETCH_FILTER_STORE_PRODUCT = "api/v1/get-store-product-with-selected-v2",
}

export enum HTTP_AUTH {
  AUTH_LOGIN = "api/v1/auth-login",
  AUTH_ME = "api/v1/me",
  AUTH_REFRESH = "api/v1/refresh",
  AUTH_REGISTER = "api/v1/auth-register-web",
  AUTH_VERIFY_OTP = "api/v1/otp-verification",
  AUTH_GENERATE_OTP = "api/v1/generate-otp",
  FETCH_USER_ADDRESS = "api/v1/get-all-delivery-address",
  ADD_USER_ADDRESS = "api/v1/add-delivery-address",
  DELETE_USER_ADDRESS = "api/v1/delete-delivery-address",
  MARK_USER_ADDRESS = "api/v1/mark-addrerss-selected",
  AUTH_UPDATE = "api/v1/update-user",
  AUTH_PASSWORD = "api/v1/update-password-api",
}
export enum HTTP_ORDER {
  MAKE_ORDER = "api/v1/order-to-merchant",
  FETCH_ALL_ORDER = "api/v1/{tag}",
  FETCH_ORDER_DETAILS = "api/v1/activity-order-details",
  POST_STORE_REVIEW = "api/v1/rating-to-merchant",
  POST_DRIVER_REVIEW = "api/v1/rating-to-driver",
}
export enum HTTP_WALLET {
  WALLET_TOTAL_ORDER = "api/v1/wallet-total-order",
  WALLET_TOTAL_PURCHASED = "api/v1/wallet-total-purchased",
  WALLET_TOTAL_TRANSACTION = "api/v1/wallet-transactions-history-v2",
}
