import {
  makeOrder,
  fetchAllOrder,
  fetchOrderDetails,
  postStoreReview,
  postDriverReview,
} from "@/services/api/order/orderApi";
export const orderActions: any = {
  async makeOrder(_: any, payload: any) {
    try {
      const response = await makeOrder(payload);
      console.log(response);
      window.location.href = response.data.data.pay_url;
    } catch (error) {
      console.log(error);
    }
  },
  async fetchAllOrder({ commit }: any, payload: any) {
    try {
      const response = await fetchAllOrder(payload);
      commit("SET_PLACED_ORDER", response.data.data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  },
  async postDriverReview({ commit }: any, payload: any) {
    try {
      const response = await postDriverReview(payload);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  },
  async postStoreReview({ commit }: any, payload: any) {
    try {
      const response = await postStoreReview(payload);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchOrderDetails({ commit }: any, payload: any) {
    try {
      const response = await fetchOrderDetails(payload);
      commit("SET_ORDER_DETAILS", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
};
