import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { getCookies, getJwtToken, removeJwtToken } from "@/utils";
import { executeHTTPAction } from "@/services/helper";
import { AUTH } from "@/services/enums/vuexEnums";
import Loader from "@/core/Loader";

export const useAxios = axios.create({
  baseURL: "https://partner.dropbuy.org/",
  //timeout: 3500, // timeout limit 2 secs
  // "X-CSRFToken": getCookies("csrftoken"),
  // "X-REQUESTED-WITH": "XMLHttpRequest",
  headers: {
    // Authorization: "Bearer " + getJwtToken(),
    // Accept: "application/json",
    // "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
  // withCredentials: true,
  validateStatus: (status) => {
    return status >= 200 && status <= 399;
  },
});

/**
 * Interceptor handler for failed Axios responses,
 * as defined by `validateStatus` in axios config
 * (see [documentation](https://axios-http.com/docs/handling_errors))
 *
 * @param   {AxiosError} error    - axios error
 * @returns {Promise<AxiosError>} - returns a promise of type axios error
 */
const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  // if (error?.response?.status == 403) {
  //   calledJwtRefresh();
  // }
  // Loader.hideLoader();
  // if (
  //   error?.response?.status == 403 &&
  //   window.location.pathname == "/store-list"
  // ) {
  //   Loader.showLoader();
  // }
  Loader.hideLoader();
  return Promise.reject(error);
};

/**
 * Interceptor handler for succesfull Axios responses
 *
 * @param   {AxiosResponse} response  - incoming axios response
 * @returns {AxiosResponse}           - axios response (pass thru)
 */
const onResponse = (response: AxiosResponse): AxiosResponse => {
  if ((window.location.pathname == "/store-list" &&  response.request.responseURL.includes("product-search-autocomplete") === false) || window.location.pathname.includes("/store-details")) {
    Loader.showLoader();
  } else {
    Loader.hideLoader();
  }
  return response;
};
const calledJwtRefresh = async () => {
  await executeHTTPAction({ actionName: AUTH.AUTH_REFRESH }).then(
    (response) => {
      // saveJwtToken(response.data.user.meta.access_token);
      // router.go(-1);
    }
  );
};
/**
 * Interceptor handler for Axios requests,
 * to start the loaderService if there is `no-header` in the http header
 *
 * Raihan: Why "no-header"? You use "no-loader".
 * And what is the purpose of this header, and where is it set?
 *
 * @param   {AxiosRequestConfig} config - request config
 * @returns {AxiosRequestConfig}        - request config
 */
const onRequest = (config: any): AxiosRequestConfig => {
  const jwt_token = getJwtToken();
  if (jwt_token) {
    config.headers["Authorization"] = `Bearer ${jwt_token}`;
  }
  if (typeof config.headers.loader !== "boolean") {
    Loader.showLoader();
  }
  return config;
};

/**
 * Handles any http request error
 *
 * @param   {AxiosError} error    - axios error
 * @returns {Promise<AxiosError>} - returns a promise of axios error
 */
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

/**
 * Axios request interceptor, delegating logic to dedicated handlers
 */
useAxios.interceptors.request.use(onRequest, onRequestError);

/**
 * Axios response interceptor, delegating logic to dedicated handlers
 */
useAxios.interceptors.response.use(onResponse, onResponseError);
