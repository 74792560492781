import {
  authLogin,
  authMe,
  authRefresh,
  authRegister,
  verifyOtp,
  generateOtp,
  fetchUserAddress,
  authUpdate,
  addUserAddress,
  deleteUserAddress,
  markUserAddressSelected,
  authPasswordUpdate,
} from "@/services/api/auth/authApi";
import { saveJwtToken, removeJwtToken } from "@/utils";
export const authActions: any = {
  async authLogin({ commit }: any, payload: any) {
    try {
      const response = await authLogin(payload);
      commit("SET_USER", response.data);
      removeJwtToken();
      saveJwtToken(response.data.meta.access_token);
      // console.log(response, "here");
      return response;
    } catch (error) {
      // console.log(error);
      return error;
    }
  },
  async getAuthUser({ commit }: any) {
    try {
      const response = await authMe();
      commit("SET_USER_ME", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async logoutAuth({ commit }: any) {
    commit("LOGOUT_USER", []);
  },
  async getRefreshToken({ commit }: any) {
    try {
      const response = await authRefresh();
      console.log("pppppppp", response);
      removeJwtToken();
      saveJwtToken(response.data.access_token);
    } catch (error) {
      console.log(error);
    }
  },
  async authRegister({ commit }: any, payload: any) {
    try {
      const response = await authRegister(payload);
      return response;
    } catch (error) {
      return error;
      console.log(error);
    }
  },
  async verifyOtp({ commit }: any, payload: any) {
    try {
      const response = await verifyOtp(payload);
      return response;
    } catch (error) {
      return error;
      console.log(error);
    }
  },
  async generateOtp({ commit }: any, payload: any) {
    try {
      const response = await generateOtp(payload);
      return response;
    } catch (error) {
      return error;
      console.log(error);
    }
  },
  async addUserAddress({ commit }: any, payload: any) {
    try {
      const response = await addUserAddress(payload);
      return response;
    } catch (error) {
      return error;
      console.log(error);
    }
  },
  async deleteUserAddress({ commit }: any, payload: any) {
    try {
      const response = await deleteUserAddress(payload);
      return response;
    } catch (error) {
      return error;
      console.log(error);
    }
  },
  async markUserAddressSelected({ commit }: any, payload: any) {
    try {
      const response = await markUserAddressSelected(payload);
      return response;
    } catch (error) {
      return error;
      console.log(error);
    }
  },
  async fetchUserAddress({ commit }: any, payload: any) {
    try {
      const response = await fetchUserAddress();
      commit("SET_USER_ADDRESS", response.data.data);
      return response;
    } catch (error) {
      return error;
      console.log(error);
    }
  },
  async authUpdate({ commit }: any, payload: any) {
    try {
      const response = await authUpdate(payload);
      console.log("after update", response);
      return response;
    } catch (error) {
      return error;
      console.log(error);
    }
  },
  async authPasswordUpdate({ commit }: any, payload: any) {
    try {
      const response = await authPasswordUpdate(payload);
      console.log("after -assword", response);
      return response;
    } catch (error) {
      return error;
      console.log(error);
    }
  },
};
