import {
  walletTotalOrder,
  WalletTodalPurchased,
  WalletTransactionHistory,
} from "@/services/api/wallet/wallet";
export const walletActions: any = {
  async fetchTransaction({ commit }: any, payload: any) {
    console.log("hello");
    try {
      const [total_order, total_purchased, transaction_history] =
        await Promise.all([
          walletTotalOrder(payload),
          WalletTodalPurchased(payload),
          WalletTransactionHistory(payload),
        ]);
      commit("SET_WALLET", {
        total_order: total_order.data.data,
        total_purchased: total_purchased.data.data,
        transaction_history: transaction_history.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  },
};
