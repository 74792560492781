import {
  fetchStoreProduct,
  fetchStoreProductDetails,
  fetchStoreDeliveryFee,
  fetchCategoryByPrime,
  filterStoreProduct,
} from "@/services/api/storeProduct/storeProduct";
export const storeProductActions: any = {
  async fetchStoreProduct({ commit }: any, payload: any) {
    console.log("hello");
    try {
      const response = await fetchStoreProduct(payload);
      commit("SET_STORE_PRODUCT", response.data.data.products);
      commit("SET_STORE_CATEGORY", response.data.data.merchent_category);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchStoreProductV2({ commit }: any, payload: any) {
    try {
      commit("SET_STORE_PRODUCT", []);
      const response = await fetchStoreProduct(payload);
      commit("SET_STORE_PRODUCT", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchStoreProductDetails({ commit }: any, payload: any) {
    console.log("hello");
    try {
      const response = await fetchStoreProductDetails(payload);
      console.log("here response", response);
      commit("SET_STORE_PRODUCT_DETAILS", response.data.data.product);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchStoreDeliveryFee({ commit }: any, payload: any) {
    console.log("hello");
    try {
      const response = await fetchStoreDeliveryFee(payload);
      console.log("here response", response);
      commit("SET_STORE_DELIVERY_FEE", response.data.data.delivery_fee);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchCategoryByPrime({ commit }: any, payload: any) {
    console.log("hello");
    try {
      const response = await fetchCategoryByPrime(payload);
      console.log("here response", response);
      commit("SET_CATEGORY_BY_PRIME", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async filterStoreProduct({ commit }: any, payload: any) {
    console.log("hello");
    try {
      const response = await filterStoreProduct(payload);
      const data: any = [];
      console.log("here response dddd", response.data.data);
      response.data.data.product.forEach(async (item: any) => {
        data.push({
          product: item,
        });
      });
      console.log(data, "wwww");
      commit("SET_STORE_PRODUCT", data);
    } catch (error) {
      console.log(error);
    }
  },
};
