import {
  fetchTopStore,
  fetchPrimeCategory,
  fetchRecentStore,
  fetchNearByStore,
  fetchMoreStore,
  fetchPrimeCategoryStore,
  fetchFilterCategory,
  sendContact,
  fetchFilterProduct,
  fetchOffer,
  sendNewsLetter,
  fetchFilterProductAutocomplete,
} from "@/services/api/home/homeApi";
export const homeActions: any = {
  async fetchInitialStore({ commit }: any, payload: any) {
    try {
      const [top_store, recent_store, nearby_store, more_store] =
        await Promise.all([
          fetchTopStore(payload),
          fetchRecentStore(payload),
          fetchNearByStore(payload),
          fetchMoreStore(payload),
        ]);
      commit("SET_INITIAL_STORE", {
        top_store: top_store.data.data,
        recent_store: recent_store.data.data,
        nearby_store: nearby_store.data.data,
        more_store: more_store.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  },
  async fetchPrimaCategory({ commit }: any, payload: any) {
    try {
      const response = await fetchPrimeCategory();
      commit("SET_PRIME_CATEGORY", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async setLoaderState({ commit }: any, payload: any) {
    commit("SET_LOADER_STATE", payload);
  },
  async fetchPrimeCategoryStore({ commit }: any, payload: any) {
    try {
      const response = await fetchPrimeCategoryStore(payload);
      commit("SET_PRIME_CATEGORY_STORE", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchFilterCategory({ commit }: any, payload: any) {
    try {
      const response = await fetchFilterCategory(payload);
      commit("SET_FILTER_CATEGORY", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchFilterProduct({ commit }: any, payload: any) {
    try {
      const response = await fetchFilterProduct(payload);
      commit("SET_FILTER_PRODUCT", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchFilterProductAutocomplete({ commit }: any, payload: any) {
    try {
      const response = await fetchFilterProductAutocomplete(payload);
      commit("SET_FILTER_PRODUCT_AUTOCCOMPLETE", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchOffer({ commit }: any, payload: any) {
    try {
      const response = await fetchOffer(payload);
      commit("SET_OFFER", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async sendContact({ commit }: any, payload: any) {
    try {
      const response = await sendContact(payload);
    } catch (error) {
      console.log(error);
    }
  },
  async sendNewsLetter({ commit }: any, payload: any) {
    try {
      const response = await sendNewsLetter(payload);
    } catch (error) {
      console.log(error);
    }
  },
};
