import { createStore } from "vuex";
import { homeInfo } from "./modules/home";
import { storeProductInfo } from "./modules/store_product";
import { authInfo } from "./modules/auth";
import { orderInfo } from "./modules/order";
import { walletInfo } from "./modules/wallet";
export const store = createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    homeInfo,
    storeProductInfo,
    authInfo,
    orderInfo,
    walletInfo,
  },
});
