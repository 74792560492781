import { useAxios } from "@/services/axios";
import { HTTP_STORE_PRODUCT } from "@/services/enums/httpEnums";
export const fetchStoreProduct = (queryParam: any): any =>
  useAxios.get(HTTP_STORE_PRODUCT.GET_STORE_PRODUCT_V2, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const fetchStoreProductDetails = (queryParam: any): any =>
  useAxios.get(HTTP_STORE_PRODUCT.GET_STORE_PRODUCT_DETAILS, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const fetchStoreDeliveryFee = (queryParam: any): any =>
  useAxios.get(HTTP_STORE_PRODUCT.GET_STORE_DELIVERY_FEE, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const fetchCategoryByPrime = (queryParam: any): any =>
  useAxios.get(HTTP_STORE_PRODUCT.FETCH_CATEGORY_BY_PRIME, {
    params: {
      ...queryParam.queryParam,
    },
  });
export const filterStoreProduct = (queryParam: any): any =>
  useAxios.get(HTTP_STORE_PRODUCT.FETCH_FILTER_STORE_PRODUCT, {
    params: {
      ...queryParam.queryParam,
    },
  });
