import { createRouter, createWebHistory } from "vue-router";
import Loader from "@/core/Loader";
import CartController from "@/components/controllers/CartController";
// Array<RouteRecordRaw>
const routes = [
  {
    path: "/",
    component: () => import("../layout/dropbuyLayoutHome.vue"),
    children: [
      {
        path: "",
        redirect: "home",
      },
      {
        path: "/home",
        name: "home",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Home/theHome.vue"
          );
        },
      },
      // this one for the basic redirection
    ],
  },
  {
    path: "/",
    component: () => import("../layout/storeLayout.vue"),
    children: [
      {
        path: "/store",
        redirect: "store-list",
      },
      {
        path: "/store-list",
        name: "listing",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/List/listing.vue"
          );
        },
      },
      {
        path: "/prime-store-list/:id",
        name: "primeListing",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Store/PrimeStore.vue"
          );
        },
      },
      {
        path: "/filter-product",
        name: "filterProduct",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Product/FilterProductView.vue"
          );
        },
      },
      {
        path: "/store-details/:id",
        name: "store_details",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Store/StoreDetails.vue"
          );
        },
      },
      {
        path: "/product-details/:id",
        name: "product_details",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Store/StoreProductDetails.vue"
          );
        },
      },
      // this one for the basic redirection
    ],
  },
  {
    path: "/",
    component: () => import("../layout/dropbuyLayoutAuth.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Login/LoginUser.vue"
          );
        },
      },
      {
        path: "/registration",
        name: "registration",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Login/RegisterUser.vue"
          );
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("../layout/dropbuyLayoutGlobal.vue"),
    children: [
      // {
      //   path: "/store",
      //   redirect: "store-list",
      // },
      // {
      //   path: "/store-list",
      //   name: "listing",
      //   component: function () {
      //     return import(
      //       /* webpackChunkName: "about" */ "../views/Modules/List/listing.vue"
      //     );
      //   },
      // },
      // {
      //   path: "/store-details/:id",
      //   name: "store_details",
      //   component: function () {
      //     return import(
      //       /* webpackChunkName: "about" */ "../views/Modules/Store/StoreDetails.vue"
      //     );
      //   },
      // },
      // {
      //   path: "/product-details/:id",
      //   name: "product_details",
      //   component: function () {
      //     return import(
      //       /* webpackChunkName: "about" */ "../views/Modules/Store/StoreProductDetails.vue"
      //     );
      //   },
      // },
      // {
      //   path: "/login",
      //   name: "login",
      //   component: function () {
      //     return import(
      //       /* webpackChunkName: "about" */ "../views/Modules/Login/LoginUser.vue"
      //     );
      //   },
      // },
      // {
      //   path: "/registration",
      //   name: "registration",
      //   component: function () {
      //     return import(
      //       /* webpackChunkName: "about" */ "../views/Modules/Login/RegisterUser.vue"
      //     );
      //   },
      // },
      {
        path: "/otp",
        name: "otp",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Login/RegisterOtp.vue"
          );
        },
      },
      {
        path: "/contact-us",
        name: "contact",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Pages/ContactUs.vue"
          );
        },
      },
      {
        path: "/about-us",
        name: "about",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Pages/AboutUs.vue"
          );
        },
      },
      {
        path: "/terms-and-conditions",
        name: "terms",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Pages/terms.vue"
          );
        },
      },
      {
        path: "/privacypolicy/",
        name: "privacy",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Pages/privacy.vue"
          );
        },
      },
      {
        path: "/delivery-service",
        name: "delivery",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Pages/delivery.vue"
          );
        },
      },

      // this one for the basic redirection
    ],
  },
  {
    path: "/account",
    component: () => import("../layout/dropbuyLayoutAccount.vue"),
    children: [
      {
        path: "/order-page/:tag",
        name: "order",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Order/OrderView.vue"
          );
        },
      },
      {
        path: "/order-details/:id",
        name: "order_details",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Order/OrderDeatilsView.vue"
          );
        },
      },
      {
        path: "/wallet",
        name: "wallet",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Wallet/WalletView.vue"
          );
        },
      },
      {
        path: "/offer",
        name: "offer",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Offer/OfferView.vue"
          );
        },
      },
      {
        path: "/address",
        name: "address",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Address/AddressView.vue"
          );
        },
      },
      {
        path: "/profile-setting",
        name: "profile",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Profile/ProfileSettingView.vue"
          );
        },
      },
      {
        path: "/password-setting",
        name: "password",
        component: function () {
          return import(
            /* webpackChunkName: "about" */ "../views/Modules/Profile/PasswordSettingView.vue"
          );
        },
      },

      // this one for the basic redirection
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.baseURL),
  routes,
});

router.beforeEach((routeTo, routeFrom, next) => {
  /**
   * Not showing the loader when a user stays in the same url
   */
  if (routeTo.path !== routeFrom.path) {
    if (routeTo.path !== "/filter-product") {
      Loader.showLoader();
    }
  }
  next();
});

router.afterEach((routeTo, routeFrom) => {
  // CartController.storeFilterCategory("default","default");
  /**
   * Not hiding the loader when a user stays in the same url
   */
  if (routeTo.path !== routeFrom.path) {
    Loader.hideLoader();
  }
});

// GA router tracking: https://matteo-gabriele.gitbook.io/vue-gtag/v/next/router-tracking
// trackRouter(router);

export default router;
