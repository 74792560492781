export const homeGetters: any = {
  getInitialStore(state: any) {
    return state.initial_store;
  },
  getPrimeCategory(state: any) {
    return state.prime_category;
  },
  getLoaderState(state: any) {
    return state.loader_state;
  },
  getPrimeStore(state: any) {
    return state.prime_category_store;
  },
  getFilterCategory(state: any) {
    return state.filter_category;
  },
  getFilterProduct(state: any) {
    return state.filter_product;
  },
  getFilterProductAutocomplete(state: any) {
    return state.filter_product_autocomplete;
  },
  getOffers(state: any) {
    return state.offers;
  },
};
